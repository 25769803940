<template>
    <div class="container">
        <el-button :type="buttonType" size="small" @click="showOpenVisible(config)" :disabled="btnDisabled">{{btnText}}
        </el-button>
        <el-dialog title="开放时间" custom-class="open-time-dialog" width="600px" :visible.sync="openVisible"
            :before-close="closeOpenViseble">
            <div class="body">
                <div class="cloumn">
                    <div :class="['left', activeIndex === index ? 'active': '']" v-for="(item, index) of weeksList"
                        :key="index" @click="handleClick(index)">
                        <div class="item">{{item.week}}</div>
                    </div>
                </div>
                <div class="right" v-loading="loading">
                    <template v-if="weeksList[activeIndex].only === 1"> <!--单次-->
                        <div :class="['form-item', weeksList[activeIndex].type == 2 ? 'ml-2': '']">
                            <div class="key">设定日期</div>
                            <div class="value">
                                <el-date-picker
                                    v-model="weeksList[activeIndex].date"
                                    type="date"
                                    placeholder="选择日期">
                                </el-date-picker>
                            </div>
                        </div>
                        <div :class="['form-item', weeksList[activeIndex].type == 2 ? 'ml-2': '']">
                            <div class="key">设定日期</div>
                            <div class="value">
                                <el-select v-model="weeksList[activeIndex].type" @change="handleClear" style="width: 220px"
                                           placeholder="请选择开放类型">
                                    <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div :class="['form-item', weeksList[activeIndex].type == 2 ? 'ml-2': '']">
                            <div class="key">开放类型</div>
                            <div class="value">
                                <el-select v-model="weeksList[activeIndex].type" @change="handleClear" style="width: 220px"
                                           placeholder="请选择开放类型">
                                    <el-option v-for="item in typeList" :key="item.value" :label="item.label"
                                               :value="item.value"></el-option>
                                </el-select>
                            </div>
                            <div class="ic-block">
                                <div class="icon" @click="openSelectCopy = true">
                                    <i class="el-icon-document-copy"></i>
                                </div>
                                <div class="icon" @click="handleAddOpenTime" v-if="weeksList[activeIndex].type == 2">
                                    <i class="el-icon-circle-plus"></i>
                                </div>
                            </div>
                        </div>
                        <div v-if="weeksList[activeIndex].type == 2">
                            <div class="form-item" v-for="(item, index) in weeksList[activeIndex].timeList" :key="index">
                                <div class="key">{{item.name}}</div>
                                <div class="value">
                                    <el-time-picker is-range v-model="item.time" value-format="timestamp"
                                                    style="width: 220px" range-separator="至" start-placeholder="开始时间"
                                                    end-placeholder="结束时间" placeholder="选择时间范围">
                                    </el-time-picker>
                                </div>
                                <div class="icon" @click="handleRemove(index)">
                                    <i class="el-icon-remove"></i>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="closeOpenViseble">取消</el-button>
                <el-button type="primary" @click="handleOpenTimeConfig">确定</el-button>
            </span>
            <el-dialog
                width="500px"
                title="复制到"
                :visible.sync="openSelectCopy"
                :before-close="closeSelectCopy"
                append-to-body>
                <div class="select-copy-list">
                    <div class="select-copy-item"
                         :class="{'active-copy': getCopyOptionIndex(item.value) > -1}"
                         v-for="(item, index) in weeksList"
                         @click="selectCopyOption(item)"
                         :key="index"
                         v-if="item.only !== 1"
                    >
                        {{ item.week }}
                    </div>
                </div>
                <div slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                    <el-button @click="closeSelectCopy">取消</el-button>
                    <el-button type="primary" @click="selectCopyChange">复制</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {},
    props: {
        id: {
            type: Number
        },
        ids: {
            type: Array
        },
        config: {
            type: String,
            default: JSON.stringify(Core.Const.WEEKS_LIST_DEVICE)
        },
        buttonType: {
            type: String,
            default: 'text'
        },
        btnText: {
            type: String,
            default: '开放时间'
        },
        btnDisabled: {
            default: false
        }
    },
    data() {
        return {
            activeIndex: 0,
            loading: false,
            openVisible: false,
            openSelectCopy: false,
            copyList: [],
            weeksList: Core.Utils.deepCopy(Core.Const.WEEKS_LIST_DEVICE),
            dialogForm: {
                id: 0,
                type: ''
            },
            typeList: [
                { value: 0, label: '开放' },
                { value: 1, label: '不开放' },
                { value: 2, label: '开放时间段' }
            ]
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {},
    methods: {
        getCopyOptionIndex(value) {
            return this.copyList.findIndex(i => i === value)
        },
        handleClick(val) {
            this.activeIndex = val;
        },
        handleClear(o) {
            if (o != 2) {
                this.weeksList[this.activeIndex].timeList = [];
            }
        },
        showOpenVisible(config) {
            this.openVisible = true;
            if (config) {
                config = JSON.parse(config);
                let isPush = true
                config.forEach((week) => {
                    week.timeList.forEach((time) => {
                        if (time.time[0]) {
                            time.time[0] = time.time[0] * 1000;
                            time.time[1] = time.time[1] * 1000;
                        }
                    });
                    if (week.only === 1) {
                        isPush = false
                    }
                });
                // if (isPush) {
                //     config.push({   // 单次生效
                //         week: '单次',
                //         type: 1,
                //         only: 1,
                //         timeList: [],
                //         date: '',
                //         value: 8
                //     })
                // }
                this.weeksList = config;
            }
        },
        selectCopyOption(item) {
            let val = item.value
            let index = this.getCopyOptionIndex(val)
            if (index > -1) {
                this.copyList.splice(index, 1)
            } else {
                this.copyList.push(val)
            }
        },
        closeSelectCopy() {
            this.copyList = []
            this.openSelectCopy = false
        },
        selectCopyChange() {
            let copyItem = this.weeksList[this.activeIndex]
            this.copyList.forEach(val => {
                let week = this.weeksList.find(item => item.value === val)
                week.type = copyItem.type
                week.timeList = Core.Utils.deepCopy(copyItem.timeList)
            })
            this.closeSelectCopy()
        },
        handleAddOpenTime() {
            let length = this.weeksList[this.activeIndex].timeList.length + 1;
            let obj = {
                name: '时间段' + length,
                time: [new Date().valueOf(), new Date().valueOf()]
            };
            this.weeksList[this.activeIndex].timeList.push(obj);
        },
        handleRemove(index) {
            this.weeksList[this.activeIndex].timeList.splice(index, 1);
        },
        handleOpenTimeConfig() {
            let times = this.weeksList.map((week, index) => {
                return {
                    week: week.week,
                    type: week.type,
                    value: index + 1,
                    timeList: week.timeList.map((item) => {
                        return {
                            name: item.name,
                            time: item.time.map((i) => {
                                return Math.round(i / 1000);
                            })
                        };
                    })
                };
            });
            /* Core.Api.Device.openTimeConfig(this.id, JSON.stringify(times)).then(
                (res) => {
                    this.$message.success('编辑成功');
                    this.openVisible = false;
                    this.$emit('refresh', res);
                }
            ); */

            let apiName = this.id ? 'openTimeConfig' : 'openTimeConfigMultiple';
            let ids = this.id ? this.id : this.ids.join(',');
            Core.Api.Device[apiName](ids, JSON.stringify(times)).then((res) => {
                this.$message.success('编辑成功');
                this.openVisible = false;
                this.$emit('refresh', res);
            });
        },
        closeOpenViseble() {
            this.weeksList = Core.Utils.deepCopy(Core.Const.WEEKS_LIST_DEVICE);
            this.openVisible = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .body {
        display: flex;
        flex-direction: row;
        .cloumn {
            display: flex;
            flex-direction: column;
            border-right: 1px solid #f2f4f7;
            width: 100px;

            .left {
                display: flex;
                justify-content: center;
                color: #000;
                font-size: 14px;

                .item {
                    cursor: pointer;
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                }
                &.active {
                    background: #ebedf0;
                    color: #1d517e;
                }
            }
        }
        .right {
            display: flex;
            flex-direction: column;
            .form-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 16px;
                &.ml-2 {
                    margin-left: 2px;
                }
                .key {
                    color: #8090a6;
                    font-size: 14px;
                    //margin-bottom: 16px;
                    margin-left: 30px;
                    width: 60px;

                    &.ml-10 {
                        margin-left: 15px;
                    }
                }
                .ic-block {
                    display: flex;
                    align-items: center;
                    //width: 200px;
                }
                .icon {
                    color: #1d517e;
                    cursor: pointer;
                    font-size: 16px;
                }
                .icon + .icon {
                    margin-left: 10px;
                }
                .value {
                    width: 240px;
                    margin-left: 26px;

                    &.ml-30 {
                        margin-left: 26px;
                    }
                }
            }
        }
    }
}
.select-copy-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .select-copy-item {
        padding: 4px 12px;
        border: 1px solid #dcdfe6;
        margin: 5px 10px 6px;
        border-radius: 2px;
        cursor: pointer;
        &.active-copy {
            border-color: #409eff;
            color: #409eff;
        }
    }
}
</style>
<style lang="scss">
.open-time-dialog .el-dialog__body{
    max-height: 50vh;
    overflow: auto;
}
</style>